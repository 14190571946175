import React, { useMemo } from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "./button";
import TextInput from "./input";
import { FormattedMessage, useIntl } from "react-intl";

const FormSchema = Yup.object().shape({
  nama_produk: Yup.string(),
  nama_pelaku_usaha: Yup.string(),
  no_sertifikat: Yup.string(),
});

const defaultPrefix = (
  <p className="self-center font-semibold text-gray-500 sm:text-center sm:text-base md:text-center">
    <FormattedMessage id="check" defaultMessage={"Cek"} />{" "}
    <span className="text-fontSecondary">
      <FormattedMessage id="halal_product" defaultMessage={"Produk Halal"} />
    </span>
  </p>
);

const SearchFormLarge = ({ type }) => {
  const intl = useIntl();
  const serialize = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };
  const getResult = (values) => {
    const value = serialize(values);
    navigate(`/search/${type}?${value}&page=1`, { replace: true });
  };

  const fields = useMemo(() => {
    if (type === "data_lembaga_pelatihan") {
      return [
        {
          id: "nama_lembaga",
          translationId: "name",
          defaultTranslation: "Nama Lembaga",
        },
      ];
    }
    if (type === "data_p3h") {
      return [
        {
          id: "nama",
          translationId: "name",
          defaultTranslation: "Nama",
        },
      ];
    }
    if (type === "data_lp3h") {
      return [
        {
          id: "nama_lembaga",
          translationId: "lph_name",
          defaultTranslation: "Nama Lembaga",
        },
      ];
    }
    if (type === "data_lph") {
      return [
        {
          id: "nama_lph",
          translationId: "lph_name",
          defaultTranslation: "Nama LPH",
        },
      ];
    }
    if (type === "data_lhln") {
      return [
        {
          id: "nama_lhln",
          translationId: "lph_name",
          defaultTranslation: "Nama LHLN",
        },
      ];
    }
    if (type === "sertifikat") {
      return [
        {
          id: "nama_produk",
          translationId: "product_name",
          defaultTranslation: "Nama Produk",
        },
        {
          id: "nama_pelaku_usaha",
          translationId: "company_name",
          defaultTranslation: "Nama Pelaku Usaha",
        },
        {
          id: "no_sertifikat",
          translationId: "cert_no",
          defaultTranslation: "Nomor Sertifikat",
        },
      ];
    }
    return [];
  }, [type]);

  const initialValues = useMemo(() => {
    return fields.reduce((prev, curr) => {
      return { ...prev, [curr.id]: "" };
    }, {});
  }, [fields]);

  if (!fields || fields.length === 0) return null;

  return (
    <>
      <Formik
        initialValues={initialValues}
        // validationSchema={FormSchema}
        onSubmit={getResult}
      >
        {(props) => (
          <form className="mb-4" onSubmit={props.handleSubmit}>
            <div className="container grid grid-flow-row-dense gap-2 sm:grid-cols-1 md:grid-cols-5 lg:grid-cols-5">
              {type === "sertifikat" && defaultPrefix}
              {fields.map((item, index) => {
                return (
                  <TextInput
                    key={`field-${index}`}
                    className="my-1 w-auto border border-2 border-purple md:my-0"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    id={item.id}
                    name={item.id}
                    value={props.values[item.id]}
                    autoComplete={"off"}
                    placeholder={intl.formatMessage({
                      id: item.translationId,
                      defaultMessage: item.defaultTranslation,
                    })}
                  />
                );
              })}
              <Button
                className="mt-2 w-full bg-purple md:mt-0 md:w-24"
                type="submit"
              >
                <FormattedMessage id="search" defaultMessage={"Cari"} />
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SearchFormLarge;
